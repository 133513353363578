<template>
    <div class="base-tag"><span>{{ text }}</span></div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
            default: null,
        }
    }
};
</script>

<style lang="less" src="./BaseTag.less"></style>
